import React, { memo } from 'react'
import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'

import './thanks.sass'

const Thanks = () => {
  return (
    <PageWrapper>
      <section className="thanksPage">
        <SectionTitle title="Contact" subtitle="お問い合わせ" />
        <p className="text-center">
          お問い合わせいただきありがとうございます。
          <br />
          お送りいただきました内容を確認の上、ご連絡させていただきます。
        </p>
      </section>
    </PageWrapper>
  )
}
export default memo(Thanks)
